<template>
  <div>
    <div class="maintitle">家政订单列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
      <el-select v-model="crntdistrict" placeholder="请选择地区" style="width:120px;">
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="timerange"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="['00:00:00', '23:59:59']"
        style="width:250px;"
        class="margin-big-left"
      ></el-date-picker>
      <el-button type="primary" @click="srch('diy')" class="margin-left">搜索</el-button>
      <el-button type="primary" @click="srch('today')" class="margin-left">今天</el-button>
      <el-button type="primary" @click="srch('yesterday')" class="margin-left">昨天</el-button>
      <el-button type="primary" @click="srch('7day')" class="margin-left">最近7天</el-button>
    </el-row>
    <el-table :data="list" border>
      <el-table-column fixed prop="ordersn" label="订单编号" width="180"></el-table-column>
      <el-table-column prop="contact" label="联系人" width="120"></el-table-column>
      <el-table-column prop="tel" label="手机号" width="120"></el-table-column>
      <el-table-column prop="servicetype" label="服务类型" width="120"></el-table-column>
      <el-table-column prop="address" label="地址"></el-table-column>
      <el-table-column prop="company" label="家政公司" width="120"></el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button v-if="scope.row.status == 1" @click="repush(scope)" type="primary" size="small">重新推送</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page="page"
      :page-sizes="pagesizes"
      :page-size="pagesize"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [], //合同列表
      district: [],
      crntdistrict: "",
      page: 1, //当前页数
      total: 0, //信息总数（所有页）
      timerange: [this.getdate("start"), this.getdate("end")], //查找时间范围，默认为本月
      srchtype:'today',//搜索时间范围
    };
  },
  methods: {
    getlist() {
      let param = {
        pagesize: this.pagesize,
        page: this.page,
        srchtype:this.srchtype,
        area:this.crntdistrict
      };
      this.$axios({
        url: this.HOST + "/jiameng/jzorder/getList",
        method: "post",
        data: param
      }).then(res => {
        this.list = res.data.list.data;
        this.total = res.data.list.total;
      });
    },
    srch(str) {
      this.srchtype = str=='diy'?this.timerange:str;
      this.page = 1;
      this.getlist();
    },
    getdate(str) {
      let date = new Date();
      if (str == "start") {
        return (
          date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + "01 00:00:00"
        );
      } else {
        return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " 23:59:59"
        );
      }
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    repush(scope){
      this.$axios({
        method:'post',
        url:this.HOST + '/jiameng/jzorder/pushOrder',
        data:{
          id:scope.row.ordersn
        }
      }).then(res=>{
        if(res.data.status == 1){
          this.$message({
            type:'info',
            message:"推送成功"
          })
        }
      });
    },
    getAreaList() {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
  },
  created: function() {
    this.getlist();
    this.getAreaList();
  }
};
</script>
